import React from "react"
import Header from "../components/header"
import { Helmet } from 'react-helmet'
import favicon from '../../static/favicon.png'
import Footer from "../components/footer"
import Layout from "../components/layout"

import "./career-listing-page.scss"

const CareerListingPage = props => {
  const listing = props.pageContext.career;

  return (
    <Layout>
      <Helmet
        title="Orange Bees | Listing"
        meta={[
          { name: 'description', content: 'Orange Bees offers flexible job opportunities for our employees with positions on-site, remote, and at our office in downtown Greenville, SC' },
          { name: 'keywords', content: 'SAP, Secuirty Anaylst, Network Analyst, .net, EDI' },
        ]}
        link={[
          { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
        ]}
      />
      <Header />
      <section className="jobScope margin-t-3 padding-t-1">
        <div className="listing" id="top_list">
          <div className="text-center">
            <h2>{listing.title}</h2>
            <h3 className="text-yellow">{listing.seniority}</h3>
            <h3>{listing.employment}</h3>
          </div>
        </div>
      </section>
      <section className="ob-cta margin-t-3 padding-t-3 padding-b-3">
        <div className="row padding-t-6 padding-b-6">
          <div className="col-xs-12 text-center">
            <h2 className="text-white">Ready to Join the Orange Bees Team?</h2>
            <a href="https://orangebees.easyapply.co/" target="_blank" rel="noopener noreferrer">
              <button>Apply Now</button>
            </a>
          </div>
        </div>
      </section>
      <section className="jobScope margin-b-6">
        <div className="listing ">
          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h2>Description</h2>
          </div>
          <p className="text-justify margin-t-3 padding-l-3 padding-r-5 ">
            {listing.description}
          </p>
          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h2>Scope</h2>
          </div>
          <p className="text-justify margin-t-3 padding-l-3 padding-r-5 ">
            {listing.scope}
          </p>

          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h2>Responsibilities</h2>
          </div>

          <div className="col-lg-12 row">
            {listing.responsibilities.map((x, i) => (
              <div key={i} className="col-md-6 col-xs-12 padding-0 padding-r-5">
                <p className="list-text">{x.description}</p>
              </div>
            ))}
          </div>

          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h2>Requirements</h2>
          </div>


          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h3 className="text-yellow">Experience</h3>
          </div>
          <div className="col-lg-12 row">
            {listing.experiences.map((x, i) => (
              <div key={i} className="col-md-6 col-xs-12 padding-0 padding-r-5">
                <p className="list-text">{x.description}</p>
              </div>
            ))}
          </div>


          <div className="col-xs-12 col-gutter-lr text-center padding-t-3 margin-t-3">
            <h3 className="text-yellow">Knowledge</h3>
          </div>
          <div className="col-lg-12 row margin-b-6">
            {listing.knowledges.map((x, i) => (
              <div key={i} className="col-md-6 col-xs-12 padding-0 padding-r-5">
                <p className="list-text">{x.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="text-center margin-t-6">
            <h2>Orange Bees Benefits</h2>
            <p className="text-justify padding-l-3 padding-r-3">
              Orange Bees offers a full package of benefits and competitive salary.
              Excellent group medical, vision, dental, life and disability insurance;
              employee training, development; advancement opportunities; and much more!
                    </p>
          </div>

          <div className="text-center margin-t-6 margin-b-6">
            <h2>Equal Opportunity Employer</h2>
            <p className="text-justify padding-l-3 padding-r-3">
              We are an equal opportunity employer. All qualified applicants will receive
              consideration for employment without regard to race, color, religion, sex,
              sexual orientation, gender identity, national origin, age, protected veteran
              status, among other things, or as a qualified individual with a disability.
              Equal Employment Opportunity is the law.
                    </p>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default CareerListingPage;
